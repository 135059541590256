import React from "react"
import Layout from "../layouts/en"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"

const PrivacyPage = () => {
    return (
        <Layout>
            <Seo id="imprint" />
            <SimplePage title="Legal notice">
                <address>
                    <strong>Fritz Finkernagel</strong><br />
                    <strong>Drahtwerk GmbH &amp; Co. KG</strong><br />
                    Westiger Straße 120-128<br />
                    D-58762 Altena<br />
                    Germany
                </address>

                <p>
                    Phone: +49 (0) 2352/ 703-0<br />
                    Fax: +49 (0) 2352/ 75447<br />
                    Email: <a href="mailto:info@finkernagel.com">info@finkernagel.com</a>
                </p>

                <dl>
                    <dt>CEO</dt>
                    <dd>Fritz Uwe Finkernagel, Timo Finkernagel</dd>

                    <dt>Individually liable general partner</dt>
                    <dd>Fritz Finkernagel Drahtwerk GmbH</dd>

                    <dt>Commercial register</dt>
                    <dd>Iserlohn Local Court - HRA 3816 / HRB 5169</dd>

                    <dt>Responsible chamber</dt>
                    <dd>SIHK Hagen</dd>

                    <dt>VAT ID number</dt>
                    <dd>DE 123842481</dd>

                    <dt>D-U-N-S number</dt>
                    <dd>325841021</dd>

                    <dt>EORI number</dt>
                    <dd>DE 4527372</dd>

                    <dt>Editorially responsible</dt>
                    <dd>Timo Finkernagel, CEO</dd>
                </dl>
            </SimplePage>
        </Layout>
    )
}

export default PrivacyPage
